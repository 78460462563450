import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgToArrayPipeModule } from 'angular-pipes';
import Proposal from 'src/app/models/Proposal';

@Component({
  selector: 'app-wizard-wrapper',
  templateUrl: './wizard-wrapper.component.html',
  styleUrls: ['./wizard-wrapper.component.css']
})

export class WizardWrapperComponent implements OnInit {

  @Input() proposal: Proposal = null;

  @Input() hasPreviousStep = true;
  @Input() hasNextStep = true;
  @Input() hasCancel = true;

  @Output() nextStep = new EventEmitter<Proposal>();
  @Output() cancel = new EventEmitter<Proposal>();
  @Output() previousStep = new EventEmitter<Proposal>();

  constructor(private router: Router) { }

  ngOnInit() {
  }

  emitPreviousStep() {
    this.previousStep.emit(this.proposal);
  }

  emitNextStep() {
    this.nextStep.emit(this.proposal);
  }

  emitCancel() {
    this.cancel.emit(this.proposal);
  }

  goToStatus(status) {
    this.router.navigate([`/wizard/proposals/edit/${status}`, this.proposal.id]);
  }

}
