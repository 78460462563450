import { Component, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormArray, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import Proposal from 'src/app/models/Proposal';
import { ProposalService } from '../../../services/proposal.service';
import { CityService } from 'src/app/services/city.service';
import BuildingType from 'src/app/models/BuildingType';
import CustomerType from 'src/app/models/CustomerType';
import MeasurementType from 'src/app/models/MeasurementType';
import { MeasurementTypeService } from 'src/app/services/measurement-type.service';
import { WizardEditBaseComponent } from '../wizard-edit-base/wizard-edit-base.component';
import Worktype from 'src/app/models/Worktype';
import { WorktypeService } from 'src/app/services/worktype.service';
import Inspection from 'src/app/models/Inspection';
import { CustomerTypeService } from 'src/app/services/customer-type.service';
import { BuildingTypeService } from 'src/app/services/building-type.service';

@Component({
  selector: 'app-wizard-qualification',
  templateUrl: './wizard-qualification.component.html',
  styleUrls: ['./wizard-qualification.component.css']
})
export class WizardQualificationComponent extends WizardEditBaseComponent {

  cities = [];
  citySearchValue = '';
  loadingCity = false;

  @Input() request: CustomerType = null;

  buildingTypes: BuildingType[] = [];
  customerTypes: CustomerType[] = [];


  measurementTypes: { measurementType: MeasurementType, checked: false }[] = [];
  files: any;

  constructor(ps: ProposalService,
  route: ActivatedRoute,
  router: Router,
  fb: FormBuilder,
  toastrService: ToastrService,
  translate: TranslateService,
  private measurementTypeService: MeasurementTypeService,
  private customerTypeService: CustomerTypeService,
  private buildingTypeService: BuildingTypeService,
  private cityService: CityService) {
    super(ps, route, router, fb, toastrService, translate);

  }

  ngOnInit() {

    super.ngOnInit();

    this.loadCustomerTypes();
    this.loadBuildingType();
    this.loadMeasurementTypes();

  }



  loadMeasurementTypes() {

    this.unsubscribe$.sink = this.measurementTypeService.list({}, 0, 99999).subscribe((data: any) => {

      this.measurementTypes = data.objects.map((mt: MeasurementType) => {
        const newMt = {measurementType: mt, checked: false};
        newMt.checked = this.item.measurements.filter((m: MeasurementType) => m.id === mt.id).length > 0;
        return newMt;
      });
      this.measurementTypes = data.objects.map((mt: MeasurementType) => {
        const newMt = {measurementType: mt, checked: false};
        newMt.checked = this.item.measurements.filter((m: MeasurementType) => m.id === mt.id).length > 0;
        return newMt;
      });


    });

  }


  createForm() {

    if (this.item == null) {
      this.item = new Proposal();
    }

    this.angForm = this.fb.group({
      buildingType: ['', Validators.required],
      customerType: ['', Validators.required],
      workType: ['', Validators.required],
      measurements: ['', ],
      hasProductService: ['', Validators.required],
      hasReading: ['', Validators.required],
      hasServiceProvision: ['', Validators.required],
      hasSoftwareLicense: ['', Validators.required],
      customerName: ['', Validators.required],
      customerDocument: [''],
      address: ['', Validators.required],
      number: ['', Validators.required],
      complement: [''],
      county: ['', Validators.required],
      city: ['', Validators.required],
      zipCode: [''],
      units: ['', Validators.required],
      responsibleName: ['', Validators.required],
      responsibleDocument: [''],
      responsibleAddress: [''],
      responsibleNumber: [''],
      responsibleComplement: [''],
      responsibleCounty: [''],
      responsibleCity: [''],
      responsibleZipCode: [''],
      responsibleEmail: [''],
      responsiblePhone: ['', Validators.required],
      comments: ['',]
    }, { validator: this.servicesValidation });

  }

  servicesValidation(fg: FormGroup) {

    const hasProductService = fg.get('hasProductService').value;
    const hasReading = fg.get('hasReading').value;
    const hasServiceProvision = fg.get('hasServiceProvision').value;
    const hasSoftwareLicense = fg.get('hasSoftwareLicense').value;
    const measurements = fg.get('measurements').value;
    const CustomerType = fg.get('customerType').value;

    if (!hasProductService && !hasReading && !hasServiceProvision && !hasSoftwareLicense) {
      return { atLeastOne: true };
    }

    if (hasServiceProvision && (hasProductService || hasReading)) {
      return { incompatibleChoices: true };
    }

    if (hasSoftwareLicense && hasReading) {
      return { incompatibleChoices: true };
    }

    return null;

  }

  loadCustomerTypes() {

    this.unsubscribe$.sink = this.customerTypeService.list({}, 0, 9999).subscribe((data: any) => {

      this.customerTypes = data.objects;

      if (this.request.customerType !== null) {

        const candidates = this.customerTypes.filter((wt: CustomerType) => wt.id === this.request.customerType.id);

        if (candidates.length > 0) {
          this.request.customerType = candidates[0];
        }

      }

    });

  }


  loadBuildingType() {
    this.unsubscribe$.sink = this.customerTypeService.list({}, 0, 9999).subscribe((data: any) => {

      this.buildingTypes = data.objects;

      if (this.request.buildingType !== null) {

        const candidates = this.buildingTypes.filter((wt: BuildingType) => wt.id === this.request.buildingType.id);

        if (candidates.length > 0) {
          this.request.buildingType = candidates[0];
        }

      }

    });
  }


  changedMeasurementType(event, measurement: MeasurementType) {

    const checked = event.target.checked;

    if (checked) {
      this.item.measurements.push(measurement);
    } else {
      this.item.measurements = this.item.measurements.filter((m: MeasurementType) => m.id !== measurement.id);
    }

  }

  setItemAttributes() {
  }

  onChangeCitySearch(e) {

    this.citySearchValue = e.term;

    if (this.citySearchValue == null || this.citySearchValue.length === 0) {

      this.citySearchValue = '';

      this.loadingCity = true;
      this.unsubscribe$.sink = this.cityService.list().subscribe((data: any) => {
        const newCities = data.objects.map(r => ({ id: r.id, name: r.name }));
        this.cities = newCities;
        this.loadingCity = false;
      });

    } else {

      const query = { name__icontains: this.citySearchValue };

      this.loadingCity = true;
      this.unsubscribe$.sink = this.cityService.list(query).subscribe((data: any) => {
        const newCities = data.objects.map(r => ({ id: r.id, name: r.name }));
        this.cities = newCities;
        this.loadingCity = false;
      });

    }

  }

  isValidForm() {

    if (this.item.measurements.length === 0) {
      return false;
    }

    return super.isValidForm();

  }

  onFocusedCity() {

    this.unsubscribe$.sink = this.cityService.list().subscribe((data: any) => {
      const newCities = data.objects.map(r => ({ id: r.id, name: r.name }));
      this.cities = newCities;
    });

  }

  onScrolledCity() {

    if (this.citySearchValue == null || this.citySearchValue.length === 0) {

      this.citySearchValue = '';

      this.unsubscribe$.sink = this.cityService.list({}, this.cities.length).subscribe((data: any) => {
        const newCities = data.objects.map(r => ({ id: r.id, name: r.name }));
        this.cities = this.cities.concat(newCities);
      });

    } else {

      const query = { name__icontains: this.citySearchValue };

      this.unsubscribe$.sink = this.cityService.list(query, this.cities.length).subscribe((data: any) => {
        const newCities = data.objects.map(r => ({ id: r.id, name: r.name }));
        this.cities = this.cities.concat(newCities);
      });

    }

  }

  onChangeMeasurements(event, measurement: MeasurementType) {
  }

  copyAddressFromCustomer() {

    this.item.responsibleAddress = this.item.address;
    this.item.responsibleNumber = this.item.number;
    this.item.responsibleCounty = this.item.county;
    this.item.responsibleZipCode = this.item.zipCode;
    this.item.responsibleCity = this.item.city;

  }

  copyAddressToClipBoard() {
    let addresText = `${this.item.address}, ${this.item.number}, ${this.item.county}`;

    if (this.item.city) {
      addresText += `, ${this.item.city.name}`;
    }

    const x = document.createElement('TEXTAREA') as HTMLTextAreaElement;
    x.value = addresText;
    document.body.appendChild(x);
    x.select();
    document.execCommand('copy');
    document.body.removeChild(x);
  }

  copyPhoneToClipBoard() {
    const phone = this.item.responsiblePhone;

    const x = document.createElement('TEXTAREA') as HTMLTextAreaElement;
    x.value = phone;
    document.body.appendChild(x);
    x.select();
    document.execCommand('copy');
    document.body.removeChild(x);
  }

  trackMeasurementTypesItemBy(index: number, item: { measurementType: MeasurementType, checked: false }) {
    return item.measurementType.id;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.measurementTypes = [];
    this.cities = [];
  }

  emitFiles() {
    this.files.emit(this.item);
  }

}
