import { OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import Model from '../../models/Model';
import { TastypieService } from '../../services/Tastypie.service';
import { SubSink } from 'subsink';

export abstract class EditBaseComponent<T extends Model> implements OnInit, OnDestroy {

  @Output() item: T = null;
  @Output() saved = new EventEmitter<any>();
  @Output() canceled = new EventEmitter<any>();

  alertTimeout = 1000;

  protected angForm: FormGroup;

  protected unsubscribe$ = new SubSink();

  constructor(protected apiService: TastypieService<T>,
              protected route: ActivatedRoute,
              protected router: Router,
              protected fb: FormBuilder,
              protected toastrService: ToastrService,
              protected translate: TranslateService) {
    this.translate.setDefaultLang('pt-BR');
    this.createForm();
  }

  abstract createForm();

  abstract setItemAttributes();

  isValidForm(): boolean {
    return !this.angForm.invalid;
  }

  validateForm() {
    return this.angForm.markAllAsTouched();
  }

  protected emitSave(modal: NgbActiveModal = null) {

    if (modal != null) {
      modal.close();
    }

    this.saved.emit('');
  }

  save(modal: NgbActiveModal = null) {

    this.setItemAttributes();

    this.validateForm();

    if (!this.isValidForm()) {
      return;
    }

    if (this.item.id == null) {

      this.unsubscribe$.sink = this.apiService.add(this.item)
        .subscribe(res => {
          this.emitSave(modal);
          this.afterSave(res);
        });

    } else {
      this.unsubscribe$.sink = this.apiService.update(this.item)
        .subscribe(res => {
        this.emitSave(modal);
        this.afterSave(res);
      });
    }

  }

  cancel() {

    this.emitCancel();

    this.translate.get('common.cancel-edit-message').subscribe((message: string) => {

      this.toastrService.warning(message, '', {
        timeOut: this.alertTimeout,
        closeButton: true,
        positionClass: 'toast-top-right'
      });

    });

  }

  protected emitCancel(modal: NgbActiveModal = null) {

    if (modal != null) {
      modal.close();
    }

    this.canceled.emit('');
  }

  afterSave(res: any = null) {

    this.translate.get('common.save-edit-message').subscribe((message: string) => {

      this.toastrService.success(message, '', {
        timeOut: this.alertTimeout,
        closeButton: true,
        positionClass: 'toast-top-right'
      });

    });

  }

  extractDatetime(p = null): Date {

    let d = p;

    const todayStr = new Date().toISOString().slice(0, 10);

    if (d === null || typeof d === 'string') {
      d = new Date(todayStr + ' ' + (d || '00:00'));
    } else {
      d = new Date(todayStr + ' ' + d.toISOString().slice(11, 19));
    }

    return d;

  }

  subtractDatetime(d1: Date, d2: Date) {

    const timeSeconds = (d1.getHours() * 3600000 + d1.getMinutes() * 60000) -
                        (d2.getHours() * 3600000 + d2.getMinutes() * 60000);

    const t = new Date(this.extractDatetime().getTime() + timeSeconds);

    return t;
  }

  addDatetime(d1: Date, d2: Date) {

    const timeSeconds = d1.getHours() * 3600000 + d1.getMinutes() * 60000 +
                        d2.getHours() * 3600000 + d2.getMinutes() * 60000;

    const t = new Date(this.extractDatetime().getTime() + timeSeconds);

    return t;
  }

  ngOnInit(): void {
  }

  trackItemBy(index: number, item: T) {
    return item.id;
  }

  ngOnDestroy(): void {
    this.toastrService.clear();
    this.unsubscribe$.unsubscribe();
  }

}
