import { Injectable } from '@angular/core';
import { TastypieService } from '../shared/services/Tastypie.service';
import User from '../models/User';

@Injectable({
  providedIn: 'root'
})

export class UserService extends TastypieService<User> {

  setUri() {
    this.uri += '/v1/organization-user';
  }

  getUser() {
    return this
      .list();
  }

  getCurrentUser() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return currentUser;
  }

}
