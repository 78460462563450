import Model from '../shared/models/Model';

export default class BlockUnit extends Model {
  name = '';
  type = 'Apartamento';
  administratorIdentfiler = '';
  consumptionFactor = '';
  rooms = '';
  area = '';
}
