import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

export class FooterComponent implements OnInit {

  version = '';
  versionDetails = '';

  public now: Date;

  constructor() { }

  ngOnInit() {
    this.now = new Date();
    this.version = environment.version;
    this.versionDetails = `Version: ${environment.version} | Build: ${environment.build} | Date: ${environment.versionDatetime}`;
  }

}
