import { Injectable } from '@angular/core';
import { TastypieService } from '../shared/services/Tastypie.service';
import ProposalSummary from '../models/Proposal';
import Proposal from '../models/Proposal';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class ProposalService extends TastypieService<Proposal> {


  setUri() {
    this.uri += '/v1/proposal';
  }

  listByStatus(status, queryParams = {}, offset: number = 0, limit: number = 10) {
    queryParams['status'] = status;
    return this.list(queryParams, offset, limit);

  }

  list(queryParams = {}, offset: number = 0, limit: number = 10) {
    return super.list(queryParams, offset, limit, Proposal);
  }

  contract(proposal: Proposal) {

    const params: HttpParams = new HttpParams().set('proposal', `${proposal.id}`);

    return this
      .http
      .get(`${this.uri}/contract/`, { params });

  }

}
