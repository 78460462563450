import Model from '../shared/models/Model';
import BlockUnit from './BlockUnit';

export default class Block extends Model {
  proposalId: number = null;
  name = '';
  type = 'Bloco';
  numberOfUnits = 1;
  firstUnit = '';
  lastUnit = '';
  step = '';
  unitsPerStage = '';
  stages = '';
  units: BlockUnit[] = [];
}
