import { Component, OnInit, Input, TemplateRef, HostListener } from '@angular/core';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})

export class ListComponent implements OnInit {

  tableHeight = 0;

  @Input() title: string;
  @Input() tableHeaderTemplate: TemplateRef<any>;
  @Input() tableBodyTemplate: TemplateRef<any>;
  @Input() paginationTemplate: TemplateRef<any>;
  @Input() actionButtonsTemplate: TemplateRef<any>;

  constructor() {
    this.getScreenSize();
   }

  ngOnInit() {
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.tableHeight = window.innerHeight * 0.65;
  }

}
