import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-column-chart',
  templateUrl: './column-chart.component.html',
  styleUrls: ['./column-chart.component.css']
})

export class ColumnChartComponent implements OnInit, OnChanges {

  @Input() title = '';
  @Input() data;
  @Input() categories: string[] = [];
  @Input() yAxisTitle = '';

  Highcharts = Highcharts;
  chart;
  chartCallback;
  updateFromInput = false;
  updateFlag = true;
  chartConstructor = 'chart';

  chartOptions = {
    chart: {
      type: 'column'
    },
    title: {
      text: this.title
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: this.categories,
    },
    yAxis: {
      title: {
        text: this.yAxisTitle
      },
      minTickInterval: 1,
      min: 0,
      stackLabels: {
        enabled: true,
        format: '{total:,.0f}',
        style: {
          fontWeight: 'bold',
          format: '{value:,.0f}',
          color: 'gray'
        }
      },
      labels: {
        enabled: true,
        format: '{value:,.0f}',
        style: {
          color: 'gray'
        }
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: false,
        }
      },
    },
    tooltip: {
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat: '{series.name}: {point.y} ({point.percentage:.1f}%)'
    },
    series: [],
  };

  constructor() {

    const self = this;

    this.chartCallback = chart => {
      self.chart = chart;
    };

  }

  ngOnInit() {

    Highcharts.setOptions({
      lang: {
        numericSymbols: null
      }
    });

    this.loadChartData();
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.data) {
      this.updateData(changes.data.currentValue);
    }

  }

  loadChartData() {
    this.updateData(this.data);
  }

  updateData(data) {

    this.chartOptions.title.text = this.title;
    this.chartOptions.series = data;
    this.chartOptions.yAxis.title.text = this.yAxisTitle;
    this.chartOptions.xAxis.categories = this.categories;

    this.updateFromInput = true;

    if (this.chart != null) {
      this.chart.hideLoading();
    }

  }

}
