import Model from '../shared/models/Model';
import { Type } from 'class-transformer';
import Worktype from './Worktype';
import Partner from './Partner';
import FlushType from './FlushType';

export default class Inspection extends Model {

  proposalId: number = null;

  type = '';
  typeStr = '';

  @Type(() => Worktype)
  workType: Worktype = null;

  @Type(() => FlushType)
  flushType: FlushType = null;

  @Type(() => Date)
  date: Date = null;

  number = 0;

  coldWaterMeters = 0;
  hotWaterMeters = 0;
  gasMeters = 0;

  allUnits: boolean = null;
  allUnitsComments = '';

  comments = '';

  @Type(() => Partner)
  indications: Partner[] = [];

}
