// app.component.ts

import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import User from './models/User';
import { ToastrService } from 'ngx-toastr';
import ProposalSummary from './models/ProposalSummary';
import { ProposalSummaryService } from './services/proposal-summary.service';
import { Subscription } from 'rxjs';
import { ProposalService } from './services/proposal.service';
import { OrganizationService } from './services/organization.service';
import Organization from './models/Organization';
import { SubSink } from 'subsink';
import { AuthenticationService } from './shared/services/authentication.service';

declare var $:any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, OnDestroy {

  title = '';
  user: User;
  // organization: Organization = null;
  welcomeMessage = false;
  alertTimeout = 3000;
  proposalSummaries: ProposalSummary[] = [];

  private updateProposalRef: Subscription = null;

  private unsubscribe$ = new SubSink();

  debug = false;

  constructor(private authenticationService: AuthenticationService,
              private router: Router,
              private translate: TranslateService,
              private toastrService: ToastrService,
              private organizationService: OrganizationService,
              private proposalService: ProposalService,
              private proposalSummaryService: ProposalSummaryService) {

    this.translate.setDefaultLang('pt-BR');
    this.user = JSON.parse(localStorage.getItem('currentUser'));

    // this.unsubscribe$.sink = this.organizationService.unique().subscribe((data: any) => {
    //   this.organization = data.objects[0];
    // });

    this.unsubscribe$.sink = this.authenticationService.loggedIn.subscribe((user: User) => {
      this.user = user;
      this.loadProposalSummaries();
    });

    this.unsubscribe$.sink = this.authenticationService.loggedOut.subscribe(res => {
      this.user = null;
      this.proposalSummaries = [];
    });

    this.unsubscribe$.sink = this.authenticationService.refreshLogin.subscribe((user: User) => {
      this.user = user;
      this.loadProposalSummaries();
    });

    if (this.isUserLoggedIn()) {
      this.loadProposalSummaries();
    }

    this.updateProposalRef = this.proposalService.getUpdateDataObservable().subscribe(() => {
      this.loadProposalSummaries();
    });

  }

  ngOnInit(): void {

    $('[data-toggle="popover"]').popover();

    if (!this.isUserLoggedIn()) {

      this.router.navigate([{outlets: {primary: '/login/' , login: 'login'}}]);

    } else {

      this.unsubscribe$.sink = this.authenticationService.updateLogin().pipe(first())
      .subscribe(
        data => {
          console.log('data');
          console.log(data);
        },
        error => {
          console.log('error');
          console.log(error);
        }
      );
    }

  }

  loadProposalSummaries() {

    this.unsubscribe$.sink = this.proposalSummaryService.list({}, 0, 9999).subscribe( (data: any) => {
      this.proposalSummaries = data.objects;
      console.log(this.proposalSummaries);
    });

  }


  isUserLoggedIn() {

    if (this.user != null && !this.welcomeMessage) {
      this.welcomeMessage = true;
      this.showWelcomeMessage();
    }

    return this.user != null;
  }

  showWelcomeMessage() {

    this.unsubscribe$.sink = this.translate.get('common.welcome-message', { name: this.user.shortName }).subscribe((message: string) => {

      this.toastrService.success(message, '', {
        timeOut: this.alertTimeout,
        closeButton: true,
        positionClass: 'toast-top-right'
      });

    });

  }

  ngOnDestroy() {
    this.updateProposalRef.unsubscribe();
    this.unsubscribe$.unsubscribe();
  }

  setDebug(value: boolean) {
    this.debug = value;
  }

}
