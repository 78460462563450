import { Injectable } from '@angular/core';
import { TastypieService } from '../shared/services/Tastypie.service';
import { HttpParams } from '@angular/common/http';
import MeasurementType from '../models/MeasurementType';
import CustomerType from '../models/CustomerType';

@Injectable({
  providedIn: 'root'
})

export class CustomerTypeService extends TastypieService<CustomerType> {

  setUri() {
    this.uri += '/v1/customer-type';
  }

  report(params: HttpParams = new HttpParams()) {
    return this
      .http
      .get(`${this.uri}/report/`, { params });
  }

  list(queryParams = {}, offset: number = 0, limit: number = 10) {
    return super.list(queryParams, offset, limit, CustomerType);
  }

}
