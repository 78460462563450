import { NgbDateAdapter, NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';

@Injectable()
export class MyDateAdapter extends NgbDateAdapter<Date> {

  readonly DELIMITER = '/';

  fromModel(value: Date | null): NgbDateStruct | null {

    if (!value) {
      return null;
    } else {
      value = new Date(value);
      value.setHours( value.getHours() + 3);
    }

    return { year: value.getFullYear(), month: value.getMonth() + 1, day: value.getDate() };

  }

  toModel(date: NgbDateStruct | null): Date | null {

    if (date == null) {
      return null;
    }

    return new Date(date.year, date.month - 1, date.day);

  }

}

@Injectable()
export class MyDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {

    if (value) {

      const date = value.split(this.DELIMITER);

      return {
        day : parseInt(date[0], 10),
        month : parseInt(date[1], 10),
        year : parseInt(date[2], 10)
      };

    }

    return null;

  }

  format(date: NgbDateStruct | null): string {
    // tslint:disable-next-line: max-line-length
    return date ? `${date.day > 9 ? date.day : '0' + date.day}${this.DELIMITER}${date.month > 9 ? date.month : '0' + date.month}${this.DELIMITER}${date.year}` : '';
  }

}



