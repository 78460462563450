import Model from '../shared/models/Model';
import CustomerType from './CustomerDocumentType';
import { Type } from 'class-transformer';
import City from './City';
import MeasurementType from './MeasurementType';
import ProposalStatus from './ProposalStatus';
import UnitGroup from './UnitGroup';
import Inspection from './Inspection';

export default class Proposal extends Model {
  [x: string]: any;

  buildingType = '';

  customerName = '';
  customerDocument = '';
  customerDocumentType = 2;

  address = '';
  number = '';
  complement = '';
  county = '';
  zipCode = '';
  units = 0;

  category = '';
  categoryStr = '';

  responsibleName = '';
  responsibleDocument = '';
  responsibleAddress = '';
  responsibleCounty = '';
  responsibleNumber = '';
  responsibleComplement = '';

  @Type(() => City)
  responsibleCity: City = null;

  responsibleZipCode = '';
  responsibleEmail = '';
  responsiblePhone = '';
  responsiblePhoneStr = '';
  responsiblePhoneComplete = '';

  comments = '';

  @Type(() => MeasurementType)
  measurements: MeasurementType[] = [];

  @Type(() => CustomerType)
  customerType: CustomerType = null;

  @Type(() => City)
  city: City = null;

  status: string = null;
  statusStr: string = null;

  createdAtTimestamp = 0;
  updatedAtTimestamp = 0;

  @Type(() => Date)
  createdAt: Date = null;

  @Type(() => Date)
  updatedAt: Date = null;

  @Type(() => Date)
  inspectionDatetime: Date = null;

  createdBy = '';
  updatedBy = '';

  createdByShortName = '';
  updatedByShortName = '';

  completeAddress = '';
  simpleAddress = '';

  @Type(() => ProposalStatus)
  availableStatus: ProposalStatus[] = [];

  @Type(() => ProposalStatus)
  nextStatus: ProposalStatus[] = [];

  @Type(() => ProposalStatus)
  previousStatus: ProposalStatus[] = [];

  @Type(() => UnitGroup)
  unitGroups: UnitGroup[] = [];

  @Type(() => Inspection)
  inspections: Inspection[] = [];

  hasReading = true;
  hasProductService = true;
  hasServiceProvision = false;
  hasSoftwareLicense = false;
  files = 0;
  documents = 0;
  images = 0;
  proposalComments = 0;
  proposalScheduledContacts = 0;


  createdDays = 0;
  currentStatusDays = 0;
  updatedDays = 0;
  isNew = false;
  isOldInStatus = false;
  mapsUrl = '';

  hotness = 0;
  hotnessStr = '';

  responsePrediction = 0;
  responsePredictionStr = '';

  productServiceContractAmount: number = null;
  productServiceContractProductAmount: number = null;
  productServiceContractServiceAmount: number = null;
  readingContractAmount: number = null;
  serviceProvisionContractAmount: number = null;
  softwareLicenseContractAmount: number = null;

  pendingClassification = true;

  @Type(() => Date)
  currentStatusDatetime: Date = null;

  madeSignatures = 0;
  requestedSignatures = 0;
  proposalId: number;
  indications: any;
  flushType: any;
  workType: any;

  hasBilled = false;

  clone() {
    const newProposal = new Proposal();
    newProposal.buildingType = this.buildingType;
    newProposal.customerName = this.customerName;
    newProposal.customerDocument = this.customerDocument;
    newProposal.customerDocumentType = this.customerDocumentType;
    newProposal.address = this.address;
    newProposal.number = this.number;
    newProposal.complement = this.complement;
    newProposal.county = this.county;
    newProposal.zipCode = this.zipCode;
    newProposal.units = this.units;
    newProposal.city = this.city;
    newProposal.responsibleName = this.responsibleName;
    newProposal.responsibleDocument = this.responsibleDocument;
    newProposal.responsibleAddress = this.responsibleAddress;
    newProposal.responsibleCounty = this.responsibleCounty;
    newProposal.responsibleNumber = this.responsibleNumber;
    newProposal.responsibleComplement = this.responsibleComplement;
    newProposal.responsibleCity = this.responsibleCity;
    newProposal.responsibleZipCode = this.responsibleZipCode;
    newProposal.responsibleEmail = this.responsibleEmail;
    newProposal.responsiblePhone = this.responsiblePhone;
    newProposal.responsiblePhoneStr = this.responsiblePhoneStr;
    newProposal.comments = this.comments;
    newProposal.measurements = this.measurements;
    newProposal.customerType = this.customerType;
    newProposal.hasReading = this.hasReading;
    newProposal.hasProductService = this.hasProductService;
    newProposal.hasServiceProvision = this.hasServiceProvision;
    newProposal.hasSoftwareLicense = this.hasSoftwareLicense;
    newProposal.hasBilled = this.hasBilled;
    return newProposal;
  }

}
