import { Injectable } from '@angular/core';
import BuildingType from '../models/BuildingType';

@Injectable({
  providedIn: 'root'
})

export class BuildingTypeService {

  private buildingTypes: BuildingType[] = null;

  constructor() { }

  list() {

    if (this.buildingTypes == null) {

      this.buildingTypes = [];

      let a = new BuildingType();
      a.id = 'type_1';
      a.label = 'building-types.type-1';
      a.videoUrl = 'https://www.youtube.com/watch?v=CmbKOsRRgWg&autoplay=1';

      this.buildingTypes.push(a);

      a = new BuildingType();
      a.id = 'type_2';
      a.label = 'building-types.type-2';
      a.videoUrl = 'https://www.youtube.com/watch?v=PbTrjRJ4sXY&autoplay=1';

      this.buildingTypes.push(a);

      a = new BuildingType();
      a.id = 'type_3';
      a.label = 'building-types.type-3';
      a.videoUrl = 'https://www.youtube.com/watch?v=Nphb_ngve3Y&autoplay=1';

      this.buildingTypes.push(a);

      a = new BuildingType();
      a.id = 'type_4';
      a.label = 'building-types.type-4';
      a.videoUrl = 'https://www.youtube.com/watch?v=j_bJjtfl3os&t=16s&ab_channel=SeuConsumo-Medi%C3%A7%C3%A3oIndividual';

      this.buildingTypes.push(a);

    }

    return this.buildingTypes;

  }

}
