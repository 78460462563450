import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import Model from '../models/Model';
import { ConfigService } from 'src/app/services/config.service';
import 'rxjs/add/operator/map';
import { plainToClass } from 'class-transformer';
import { toJSDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar';

interface TastypieInterface<T extends Model> {
  objects: T[];
  deletedObjects: T[];
  offset: number;
  limit: number;
  total: number;
}

@Injectable()
export abstract class TastypieService<T extends Model> {

  uri: string;

  private updateData: Subject<void> = null;
  private updateDataObservable: Observable<void> = null;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json' })
  };

  constructor(protected http: HttpClient, protected config: ConfigService) {
    this.uri = config.baseUrl;
    this.setUri();
  }

  getUpdateDataObservable(): Observable<void> {

    if (this.updateData === null) {
      this.updateData = new Subject<void>();
      this.updateDataObservable = this.updateData.asObservable();
    }

    return this.updateDataObservable;

  }

  abstract setUri();

  list(queryParams = {}, offset: number = 0, limit: number = 10, ModelClass: any = null) {
    const params: HttpParams = new HttpParams({ fromObject: queryParams });
    return this.objects(offset, limit, params, ModelClass);
  }

  add(obj) {
    return this.http.post(`${this.uri}/`, obj);
  }

  update(obj) {
    return this
      .http
      .put(`${this.uri}/${obj.id}/`, obj);
  }

  save(obj) {

    console.log(obj);

    if (obj.id && obj.id > 0) {
      return this.update(obj);
    } else {
      return this.add(obj);
    }

  }

  bulk(items: T[]) {

    //
    // Objects with no resourceUri will be created and
    // with resourceUri will be updated.
    //
    const objects = items.filter((item: Model) => !item.deleted);

    //
    // Only resource uri
    //
    const deletedObjects = items.filter((item: Model) => item.deleted && item.resourceUri !== null && item.resourceUri !== undefined)
      .map((item: Model) => item.resourceUri);

    const obj = { objects, deletedObjects };

    return this.http.patch(`${this.uri}/`, obj);

  }

  delete(id) {
    return this
      .http
      .delete(`${this.uri}/${id}/`);
  }

  get(id, ModelClass: any = null) {

    return this
      .http
      .get(`${this.uri}/${id}/`)
      .map((res: T) => {

        if (ModelClass === null) {
          return res;
        }

        const a: T = plainToClass(ModelClass, res, { enableCircularCheck: true });
        return a;

      });

  }

  protected objects(offset: number = 0, limit: number = 10, params: HttpParams = new HttpParams(), ModelClass: any = null) {

    params = params.set('offset', offset.toString());
    params = params.set('limit', limit.toString());

    return this
      .http
      .get<TastypieInterface<T>>(`${this.uri}/`, { params })
      .map((res: TastypieInterface<T>) => {

        if (ModelClass === null) {
          return res;
        }

        res.objects = res.objects.map((d: T) => {
          const a: T = plainToClass(ModelClass, d, { enableCircularCheck: true });
          return a;
        });

        return res;

      });

  }

  report(params: HttpParams = new HttpParams()): Observable<object> {
    throw new Error('report not implemented in TastypieService');
  }

  convertBase64ToBlobData(base64Data: string, contentType: string = 'image/png', sliceSize = 512) {

    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });

    return blob;

  }

  itemById(id, ModelClass: any = null): Observable<T> {

    return this
      .http
      .get<T>(`${this.uri}/${id}/`)
      .map((d: T) => {

        if (ModelClass === null) {
          return d as T || null;
        }

        const a: T = plainToClass(ModelClass, d, { enableCircularCheck: true });
        return a;

      });

  }

  notifyMustUpdate() {
    this.getUpdateDataObservable();
    this.updateData.next();
  }

}
