import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { UserListComponent } from './components/user/user-list/user-list.component';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },

  { path: 'home', loadChildren: './components/home/home.module#HomeModule' },

  { path: 'proposals', loadChildren: './components/proposal/proposal.module#ProposalModule' },
  { path: 'prospection', loadChildren: './components/prospection/prospection.module#ProspectionModule' },
  { path: 'wizard', loadChildren: './components/wizard/wizard.module#WizardModule' },

  { path: 'settings/items', loadChildren: './components/item/item.module#ItemModule' },
  { path: 'settings/item-types', loadChildren: './components/item-type/item-type.module#ItemTypeModule' },
  { path: 'settings/item-prices', loadChildren: './components/item-price/item-price.module#ItemPriceModule' },
  { path: 'settings/contract-templates', loadChildren: './components/contract-template/contract-template.module#ContractTemplateModule' },

  // Authentication
  { path: 'login', component: LoginComponent, outlet: 'login' },

  // Others
  { path: '**', redirectTo: '' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})

export class AppRoutingModule { }
