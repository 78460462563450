import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import CustomerType from '../models/CustomerType';
import { CustomerTypeService } from '../services/customer-type.service';

@Injectable()
export class CustomerTypeResolve implements Resolve<CustomerType[]> {

  constructor(private service: CustomerTypeService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<CustomerType[]> {
    return this.service.list({}, 0, 9999).pipe(map((res: any) => res.objects));
  }

}
