import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../services/authentication.service';
import { AlertService } from '../services/alert.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private alertService: AlertService,
              private authenticationService: AuthenticationService,
              private toast: ToastrService,
              private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe( tap(() => {}, (err: any) => {

      if (err instanceof HttpErrorResponse) {

        if (!err.ok) {

          if (err.status === 401) {
            this.authenticationService.logout();
            this.router.navigate([{outlets: {primary: '/login/' , login: 'login'}}]);
          }

          const message = `${err.status}: ${err.error.message || err.error.errorMessage || err.statusText}`;
          this.alertService.error(message);

        }
      }

    }));

  }

}
