import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import Organization from 'src/app/models/Organization';
import { OrganizationService } from 'src/app/services/organization.service';
import { SubSink } from 'subsink';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit, OnDestroy {

  @Input() isUserLoggedIn = false;

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;

  private unsubscribe$ = new SubSink();

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    // reset login status
    this.authenticationService.logout();

    // get return url from route parameters or default to '/'
    const returnUrl = 'returnUrl';
    this.returnUrl = this.route.snapshot.queryParams[returnUrl] || '/';

  }

  onSubmit() {

    this.submitted = true;

    this.alertService.clear();

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    const username = this.loginForm.get('username').value;
    const password = this.loginForm.get('password').value;

    this.loading = true;
    this.unsubscribe$.sink = this.authenticationService.login(username, password)
      .pipe(first())
      .subscribe(
        data => {
          this.router.navigate([this.returnUrl]);
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
        });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }

}
