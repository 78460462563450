import { ProposalService } from 'src/app/services/proposal.service';
import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import Proposal from 'src/app/models/Proposal';
import { WizardEditBaseComponent } from '../wizard-edit-base/wizard-edit-base.component';

@Component({
  selector: 'app-wizard-empty',
  templateUrl: './wizard-empty.component.html',
  styleUrls: ['./wizard-empty.component.css']
})

export class WizardEmptyComponent extends WizardEditBaseComponent {

  constructor(ps: ProposalService,
    route: ActivatedRoute,
    router: Router,
    fb: FormBuilder,
    toastrService: ToastrService,
    translate: TranslateService) {
      super(ps, route, router, fb, toastrService, translate);

  }

  ngOnInit() {
    super.ngOnInit();
  }

  createForm() {

    if (this.item == null) {
      this.item = new Proposal();
    }

    this.angForm = this.fb.group({
    });

  }

  setItemAttributes() {
  }

}
