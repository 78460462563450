import { Injectable } from '@angular/core';
import { TastypieService } from '../shared/services/Tastypie.service';
import FlushType from '../models/FlushType';

@Injectable({
  providedIn: 'root'
})

export class FlushTypeService extends TastypieService<FlushType> {

  setUri() {
    this.uri += '/v1/flush-type';
  }

  list(queryParams = {}, offset: number = 0, limit: number = 10) {
    return super.list(queryParams, offset, limit, FlushType);
  }

}
