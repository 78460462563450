import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import User from "../../models/User";
import * as $ from "jquery";
import * as AdminLte from "admin-lte";
import ProposalSummary from "src/app/models/ProposalSummary";
import Organization from "src/app/models/Organization";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.css"],
})
export class MenuComponent implements OnInit {
  @Input() user: User;
  @Input() proposalSummaries: ProposalSummary[] = [];

  constructor(private toastrService: ToastrService) {}

  ngOnInit() {}

  showStatusHelp(summary: ProposalSummary) {

    this.toastrService.clear();

    if (summary.statusHelp === null || summary.statusHelp.length === 0) {
      return;
    }

    this.toastrService.info(summary.statusHelp, summary.statusStr, {
      timeOut: null,
      tapToDismiss: true,
      disableTimeOut: true,
      closeButton: true,
      positionClass: "toast-center-center",
    });
  }
}
