import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.css']
})

export class LineChartComponent implements OnInit, OnChanges {

  @Input() platform = '';
  @Input() data;
  @Input() yAxisTitle = '';
  @Input() categories: Array<string>;

  Highcharts = Highcharts;
  chart;
  chartCallback;
  updateFromInput = false;
  updateFlag = true;
  chartConstructor = 'chart';

  chartOptions = {
    title: {
      text: this.platform
    },
    credits: {
      enabled: false
    },
    xAxis: {
      type: 'datetime',
    },
    yAxis: {
      title: {
        text: this.yAxisTitle
      },
    },
    plotOptions: {
      line: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
        }
      }
    },
    series: [],
  };

  constructor() {

    const self = this;

    this.chartCallback = chart => {
      self.chart = chart;
    };

  }

  ngOnInit() {
    this.loadChartData();
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.data) {
      this.updateData(changes.data.currentValue);
    }

  }

  loadChartData() {

    this.updateData(this.data);

  }

  updateData(data) {

    this.chartOptions.title.text = this.platform;
    this.chartOptions.series = data;
    this.chartOptions.yAxis.title.text = this.yAxisTitle;

    this.updateFromInput = true;

    if (this.chart != null) {
      this.chart.hideLoading();
    }

  }

}
