import Model from '../shared/models/Model';
import Proposal from './Proposal';

export default class ProposalSummary extends Model {
  status = '';
  statusStr = '';
  statusHelp: string = null;
  delayed = 0;
  scheduledContact = 0;
  total = 0;
  initial = 0;
  final = 0;
  min = 0;
  max = 0;
  proposals: Proposal[] = [];

  totalNewStatus = 0;
  totalOldStatus = 0;
  proposalsNewStatus: Proposal[] = [];
  proposalsOldStatus: Proposal[] = [];

  totalAmountProductServiceNewStatus = 0;
  totalAmountReadingNewStatus = 0;
  totalAmountSoftwareNewStatus = 0;
  totalAmountServiceProvisionNewStatus = 0;

  totalAmountProductServiceOldStatus = 0;
  totalAmountReadingOldStatus = 0;
  totalAmountSoftwareOldStatus = 0;
  totalAmountServiceProvisionOldStatus = 0;

}
