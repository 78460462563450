import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})

export class PaginationComponent implements OnInit {

  @Input() offset: number;
  @Input() limit: number;
  @Input() total: number;

  pagesMaxRange = 2;
  itemsPerPageOptions = [10, 20, 50];

  @Output() firstPage = new EventEmitter();
  @Output() nextPage = new EventEmitter();
  @Output() page = new EventEmitter<number>();
  @Output() previousPage = new EventEmitter();
  @Output() lastPage = new EventEmitter();
  @Output() itemsPerPage = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
  }

  currentPage() {
    return Math.ceil((this.offset) / this.limit) + 1;
  }

  minPage() {
    return 1;
  }

  numberOfPages() {
    return Math.ceil(this.total / this.limit);
  }

  pages() {

    const t: Array<number> = [];

    const currentPage = this.currentPage();
    const minPage = 1;
    const maxPage = this.numberOfPages();

    let begin = Math.max(minPage, currentPage - this.pagesMaxRange);
    let end = Math.min(maxPage, currentPage + this.pagesMaxRange);

    const delta = (2 * this.pagesMaxRange) - (end - begin);

    if (delta !== 0) {
      begin = Math.max(minPage, begin - this.pagesMaxRange);
      end = Math.min(maxPage, end + this.pagesMaxRange);
    }

    for (let i = begin; i <= end; i = i + 1) {
      t.push(i);
    }

    return t;
  }

  emitNextPage() {
    this.nextPage.emit();
  }

  emitPreviousPage() {
    this.previousPage.emit();
  }

  emitPage(page: number) {
    const newOffset = ((page - 1) * this.limit);
    this.page.emit(newOffset);
  }

  emitFirstPage() {
    this.firstPage.emit();
  }

  emitLastPage() {
    this.lastPage.emit();
  }

  fromItemIndex(): number {
    return this.offset + 1;
  }

  toItemIndex(): number {
    return Math.min((this.offset + this.limit), this.total);
  }

  totalItems(): number {
    return this.total;
  }

  hasPreviousPage(): boolean {
    return this.offset > 0;
  }

  hasNextPage(): boolean {
    return this.offset + this.limit < this.total;
  }

  emitItemsPerPage() {
    this.itemsPerPage.emit(this.limit);
  }

}
