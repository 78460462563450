import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.css']
})

export class PieChartComponent implements OnInit, OnChanges {

  @Input() title = '';
  @Input() data;

  Highcharts = Highcharts;
  chart;
  chartCallback;
  updateFromInput = false;
  updateFlag = true;
  chartConstructor = 'chart';

  chartOptions: Highcharts.Options = {
    chart: {
      type: 'pie'
    },
    title: {
      text: this.title
    },
    credits: {
      enabled: false
    },
    tooltip: {
      pointFormat: '{point.name}: <b>{point.y} ({point.percentage:.1f}%)</b>'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.y} ({point.percentage:.1f}%)',
        },
        showInLegend: true
      }
    },
    series: [],
  };

  constructor() {

    const self = this;

    this.chartCallback = chart => {
      self.chart = chart;
    };

  }

  ngOnInit() {
    this.loadChartData();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateData(changes.data.currentValue);
  }

  loadChartData() {
    this.updateData(this.data);
  }

  updateData(data) {

    const newSeries = [];
    newSeries.push({ name: '', colorByPoint: true, data });

    this.chartOptions.series = newSeries;
    this.updateFromInput = true;

    if (this.chart != null) {
      this.chart.hideLoading();
    }

  }

}
