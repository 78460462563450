
import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import Block from 'src/app/models/Block';
import BlockUnit from 'src/app/models/BlockUnit';
import Proposal from 'src/app/models/Proposal';
import Unit from 'src/app/models/Unit';
import { BlockService } from 'src/app/services/block.service';
import { ProposalService } from 'src/app/services/proposal.service';
import { EditListBaseComponent } from 'src/app/shared/components/edit-list-base/edit-list-base.component';



@Component({
  selector: 'app-wizard-estimate.block-units',
  templateUrl: './wizard-estimate.component.html',
  styleUrls: ['./wizard-estimate.component.css']
})
export class WizardEstimateComponent extends EditListBaseComponent<Proposal, Block> {

  proposalId: number = null;
  proposal: Proposal = null;

  currentBlockPosition = 0;
  currentBlock: Block = null;

  formUnits: FormGroup;

  constructor(ps: BlockService,
    route: ActivatedRoute,
    router: Router,
    fb: FormBuilder,
    toastrService: ToastrService,
    translate: TranslateService,
    private proposalService: ProposalService,
    private blockService: BlockService) {
    super(ps, route, router, fb, toastrService, translate);
  }

  ngOnInit() {

    this.route.params.subscribe(
      params => {
        this.proposalId = params['id'];

        if (this.proposalId) {
          this.loadProposal();
        }

      }
    );

  }

  loadProposal() {

    const query = {};
    query['id'] = this.proposalId;

    this.proposalService.list(query, 0, 1).subscribe((data: any) => {
      this.proposal = data.objects[0];
      this.loadBlocks();
    });

  }

  loadBlocks() {

    const query = {};
    query['proposal'] = this.proposal.id;

    this.unsubscribe$.sink = this.translate.get('common.loading-data').subscribe((message: string) => {

      this.toastrService.success('', message, {
        timeOut: null,
        disableTimeOut: true,
        tapToDismiss: false,
        closeButton: false,
        positionClass: 'toast-center-center'
      });

      this.unsubscribe$.sink = this.apiService.list(query, 0, 9999).subscribe((data: any) => {

        this.toastrService.clear();

        this.items = data.objects;

        this.items.forEach((b: Block) => {
          b.proposalId = this.proposal.id;
          this.angFormBlocks.push(this.createBlock());
        });

        if (this.items.length > 0) {

          this.setCurrentBlock(0);

          this.currentBlock.units.forEach((u: BlockUnit) => {
            this.angFormUnits.push(this.createUnit());
          });

        }

      }, (error) => {

        this.toastrService.clear();

        this.toastrService.error('', error, {
          timeOut: 5000,
          closeButton: true,
          positionClass: 'toast-center-center'
        });

      });

    });

  }

  createForm() {

    this.angForm = this.fb.group({
      blocks: this.fb.array([])
    });

    this.formUnits = this.fb.group({
      units: this.fb.array([])
    });

  }

  createBlock(): FormGroup {

    return this.fb.group({
      name: ['', Validators.required],
      type: ['', Validators.required],
      numberOfUnits: ['', Validators.required],
      firstUnit: ['', Validators.required],
      lastUnit: ['', Validators.required],
      step: ['', Validators.required],
      unitsPerStage: ['', Validators.required],
      stages: ['', Validators.required],

    });

  }

  addBlock(): void {

    const block = new Block();
    block.proposalId = this.proposal.id;

    this.items.push(block);

    this.setCurrentBlock(this.items.length - 1);

    this.angFormBlocks.push(this.createBlock());
    this.angForm.markAsDirty();
    this.angForm.markAsTouched();

  }

  get angFormBlocks() {
    return this.angForm.get('blocks') as FormArray;
  }

  get angFormUnits() {
    return this.formUnits.get('units') as FormArray;
  }

  addUnit(): void {

    this.angFormUnits.push(this.createUnit());
    this.formUnits.markAsDirty();
    this.formUnits.markAsTouched();

  }

  createUnit(): FormGroup {

    return this.fb.group({
      name: ['', Validators.required],
      administratorIdentfiler: ['', Validators.required],
      type: ['', Validators.required],
      consumptionFactor: ['', Validators.required],
      rooms: ['', Validators.required],
      area: ['', Validators.required],
    });

  }

  buildUnits(pos: number) {

    this.setCurrentBlock(pos);

    const block = this.items[pos];

    this.blockService.generateUnits(block.numberOfUnits, block.firstUnit, block.lastUnit,
      block.step, block.unitsPerStage, block.stages).subscribe((data: any) => {

        if (data.status) {

          this.currentBlock = block;

          block.units = [];

          data.objects.forEach((unitName: string, unitCodAdministration: string, unitBlockTypeUnit: string, unitConsumptionFactor: string, unitRooms: string, uniArea: string) => {
            const newUnit = new BlockUnit();
            newUnit.name = unitName;
            newUnit.administratorIdentfiler = unitCodAdministration;
            newUnit.type = unitBlockTypeUnit;
            newUnit.consumptionFactor = unitConsumptionFactor;
            newUnit.rooms = unitRooms;
            newUnit.area = uniArea;

            block.units.push(newUnit);
            this.addUnit();
          });


        } else {

          this.toastrService.error(data.message, '', {
            timeOut: 5000,
            closeButton: true,
            positionClass: 'toast-top-right'
          });


        }

      });

  }

  setItemAttributes() {

  }

  setCurrentBlock(pos: number) {
    this.currentBlockPosition = pos;
    this.currentBlock = this.items[pos];
  }

  afterSave() {
    super.afterSave();
    this.router.navigate(['proposals', this.proposal.status]);
  }

  cancel() {
    this.router.navigate(['proposals', this.proposal.status]);
  }

}

