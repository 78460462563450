import { ProposalService } from 'src/app/services/proposal.service';
import { Component, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { WizardEditBaseComponent } from '../wizard-edit-base/wizard-edit-base.component';
import MeasurementType from 'src/app/models/MeasurementType';
import Inspection from 'src/app/models/Inspection';


@Component({
  selector: 'app-wizard-analysis',
  templateUrl: './wizard-analysis.component.html',
  styleUrls: ['./wizard-analysis.component.css']
})


export class WizardAnalysisComponent extends WizardEditBaseComponent {


  @Input() inspection: Inspection = null;


  constructor(ps: ProposalService,
    route: ActivatedRoute,
    router: Router,
    fb: FormBuilder,
    toastrService: ToastrService,
    translate: TranslateService) {
    super(ps, route, router, fb, toastrService, translate);

  }

  measurementTypes: { measurementType: MeasurementType, checked: false }[] = [];
  files: any;

  ngOnInit() {
    super.ngOnInit();

    if (this.inspection == null) {
      this.inspection = new Inspection();
    }

  }

  afterLoadProposal() {

    if (this.inspection.id) {
      this.loadInspection();
    }

  }

  createForm() {

    if (this.inspection == null) {
      this.inspection = new Inspection();
    }
    this.angForm = this.fb.group({
      date: ['', Validators.required],
      time: ['', Validators.required],
    });
  }

  loadInspection() {

    this.unsubscribe$.sink = this.apiService.get(this.inspection.id).subscribe((data: any) => {

      this.inspection = data;
      this.inspection.proposalId = this.inspection.id;

    });

  }

  setItemAttributes() {
  }

}
