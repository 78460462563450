import { OnInit, Output, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TastypieService } from '../../services/Tastypie.service';
import Model from '../../models/Model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { formatNumber } from '@angular/common';
import { SubSink } from 'subsink';

export abstract class EditListBaseComponent<T extends Model, S extends Model> implements OnInit, OnDestroy, AfterViewInit {

  @Output() parentItem: T = null;
  @Output() items: S[] = [];
  @Output() saved = new EventEmitter<any>();

  alertTimeout = 1000;

  angForm: FormGroup;
  limit = 20;

  protected unsubscribe$ = new SubSink();

  constructor(protected apiService: TastypieService<S>,
              protected route: ActivatedRoute,
              protected router: Router,
              protected fb: FormBuilder,
              protected toastrService: ToastrService,
              protected translate: TranslateService) {
    this.translate.setDefaultLang('pt-BR');
    this.createForm();
  }

  ngAfterViewInit(): void {

  }

  abstract createForm();

  abstract setItemAttributes();

  isValidForm(): boolean {
    return !this.angForm.invalid;
  }

  validateForm() {
    this.angForm.markAllAsTouched();
  }

  protected emitSave() {
    this.saved.emit('');
  }

  saveAndContinue(savingMore = false, localOffset = 0, localToastr: any = null) {
    this.save(savingMore, localOffset, localToastr, true);
  }

  save(savingMore = false, localOffset = 0, localToastr: any = null, saveAndcontinue = false) {

    console.log('1:' + localOffset);

    if (!savingMore && !this.isValidForm()) {
      this.validateForm();
      return;
    }

    if (localOffset > this.items.length) {
      return;
    }

    const progress = Math.min(((localOffset + this.limit) * 100) / (this.items.length || 1), 100.0);
    const progressStr = formatNumber(progress, 'pt-pt', '.1-1');

    this.unsubscribe$.sink = this.translate.get('common.saving-data-progress', { progress: progressStr }).subscribe((message: string) => {

      if (localToastr == null) {

        localToastr = this.toastrService.success('', message, {
          timeOut: null,
          extendedTimeOut: null,
          closeButton: false,
          tapToDismiss: false,
          disableTimeOut: true,
          positionClass: 'toast-center-center'
        });

      } else {
        localToastr.toastRef.componentInstance.title = message;
      }

      const localItems = this.items.slice(localOffset, localOffset + this.limit);

      this.unsubscribe$.sink = this.apiService.bulk(localItems).subscribe(res => {

        localOffset += this.limit;

        console.log('2:' + localOffset);

        if (localOffset < this.items.length) {
          this.save(true, localOffset, localToastr, saveAndcontinue);
        } else {
          localToastr = null;
          this.toastrService.clear();

          if (saveAndcontinue) {
            this.afterSaveAndContinue();
          } else {
            this.afterSave();
          }

          this.emitSave();
        }

      }, () => {
        this.toastrService.clear();
      });

    });

  }

  cancel() {

    this.unsubscribe$.sink = this.translate.get('common.cancel-edit-message').subscribe((message: string) => {

      this.toastrService.warning(message, '', {
        timeOut: this.alertTimeout,
        closeButton: true,
        positionClass: 'toast-top-right'
      });

    });

  }

  afterSave(item: S = null) {

    window.scroll(0, 0);

    this.unsubscribe$.sink = this.translate.get('common.save-edit-message').subscribe((message: string) => {

      this.toastrService.success(message, '', {
        timeOut: this.alertTimeout,
        closeButton: true,
        positionClass: 'toast-top-right'
      });

    });

  }

  afterSaveAndContinue(item: S = null) {

    window.scroll(0, 0);

    this.unsubscribe$.sink = this.translate.get('common.save-edit-message').subscribe((message: string) => {

      this.toastrService.success(message, '', {
        timeOut: this.alertTimeout,
        closeButton: true,
        positionClass: 'toast-top-right'
      });

    });

  }

  ngOnInit(): void {
  }

  trackItemBy(index: number, item: T) {
    return item.id;
  }

  ngOnDestroy(): void {
    this.toastrService.clear();
    this.unsubscribe$.unsubscribe();
  }

}
