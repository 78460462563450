import { NgbTimeAdapter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

export class MyTimeAdapter extends NgbTimeAdapter<Date> {

  fromModel(date: Date): NgbTimeStruct {

    if (!date) {
      date = new Date(2000, 0, 1, 0, 0, 0);
    } else {
      const dateValue = String(date);
      const values = dateValue.split(':');
      date = new Date(2000, 0, 1, Number.parseInt(values[0], 10), Number.parseInt(values[1], 10), Number.parseInt(values[2], 10));
    }

    return { hour: date.getHours(), minute: date.getMinutes(), second: date.getSeconds() };

  }

  toModel(date: NgbTimeStruct): Date {

    if (date == null) {
      return null;
    }

    return new Date(2000, 0, 1, date.hour - 2, date.minute, date.second);
  }

}
