import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import MeasurementType from '../models/MeasurementType';
import { MeasurementTypeService } from '../services/measurement-type.service';

@Injectable()
export class MeasurementTypeResolve implements Resolve<MeasurementType[]> {

  constructor(private service: MeasurementTypeService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<MeasurementType[]> {
    return this.service.list({}, 0, 9999).pipe(map((res: any) => res.objects));
  }

}
