import Model from '../shared/models/Model';

export default class City extends Model {
  name = '';
  code = '';
  coordinates = '';
  mainCity = false;
  lat: number = null;
  lng: number = null;
}
