import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})

export class ModalComponent implements OnInit {

  @Input() title: string;
  @Input() applyButtonTitle: string;
  @Input() canApply = true;

  @Input() hasApplyButton = true;
  @Input() hasCancelButton = true;
  @Input() hasClearButton = false;
  @Input() modalSize = 'md';

  @Output() apply = new EventEmitter<any>();
  @Output() clear = new EventEmitter<any>();

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  emitApply() {
    this.apply.emit(this.activeModal);
  }

  emitClear() {
    this.clear.emit(this.activeModal);
  }

  close() {
    this.activeModal.close('Close click');
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {

    const target: any = event.target || event.currentTarget;

    // Skip Enter button when in a textarea
    if (target !== null && target.localName === 'textarea') {
      return;
    }

    if (event.key === 'Enter') {
      this.emitApply();
    }

  }

}
