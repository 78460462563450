import { ProposalService } from 'src/app/services/proposal.service';
import { Component, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { EditBaseComponent } from 'src/app/shared/components/edit-base/edit-base.component';
import Proposal from 'src/app/models/Proposal';

@Component({
  selector: 'app-wizard-edit-base',
  templateUrl: './wizard-edit-base.component.html',
  styleUrls: ['./wizard-edit-base.component.css']
})

export class WizardEditBaseComponent extends EditBaseComponent<Proposal> {

  constructor(ps: ProposalService,
              route: ActivatedRoute,
              router: Router,
              fb: FormBuilder,
              toastrService: ToastrService,
              translate: TranslateService) {
    super(ps, route, router, fb, toastrService, translate);
  }

  ngOnInit() {

    super.ngOnInit();

    this.route.params.subscribe(
      params => {

        const proposalId = params['id'];

        if (proposalId) {
          this.loadProposal(proposalId);
        }

      }
    );

  }

  loadProposal(proposalId: number) {

    const query = {};
    query['id'] = proposalId;

    this.apiService.list(query, 0, 1).subscribe((data: any) => {
      this.item = data.objects[0];
      this.afterLoadProposal();
    });

  }

  setItemAttributes() {
  }

  createForm() {
    throw new Error('Method not implemented.');
  }

  afterLoadProposal() {
  }

}

