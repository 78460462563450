import Model from '../shared/models/Model';

export default class User extends Model {
  name: string;
  shortName: string;
  apiKey: string;
  photo: string;
  username: string;
  email: string;
  password: string;
  photoFilename: string = null;
  photoContent: string = null;
  photoOriginal: string;
  cropping: string;
}
