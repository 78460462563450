import { Injectable } from '@angular/core';
import { TastypieService } from '../shared/services/Tastypie.service';
import ProposalSummary from '../models/ProposalSummary';

@Injectable({
  providedIn: 'root'
})

export class ProposalSummaryService extends TastypieService<ProposalSummary> {

  setUri() {
    this.uri += '/v1/proposal-summary';
  }

  list(queryParams = {}, offset: number = 0, limit: number = 10) {
    return super.list(queryParams, offset, limit, ProposalSummary);
  }

}
