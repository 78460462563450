import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { TastypieService } from '../shared/services/Tastypie.service';
import Organization from '../models/Organization';

@Injectable({
  providedIn: 'root'
})

export class OrganizationService extends TastypieService<Organization> {

  setUri() {
    this.uri += '/v1/organization';
  }

  unique() {
    return this.list({}, 0, 1);
  }

  list(queryParams = {}, offset: number = 0, limit: number = 10) {
    return super.list(queryParams, offset, limit, Organization);
  }

}
