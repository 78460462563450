import { Component, OnInit, Input, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ProposalService } from 'src/app/services/proposal.service';
import Proposal from 'src/app/models/Proposal';
import { NgbModalOptions, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import Inspection from 'src/app/models/Inspection';
import UnitGroup from 'src/app/models/UnitGroup';
import User from 'src/app/models/User';

@Component({
  selector: 'app-proposal-list',
  templateUrl: './proposal-list.component.html',
  styleUrls: ['./proposal-list.component.css']
})

export class ProposalListComponent implements OnInit, OnDestroy {

  customerSearch = '';
  proposals: Proposal[] = [];
  status = '';
  showStatus = false;

  @Input() user: User;

  modalRef: NgbModalRef = null;

  constructor(private apiService: ProposalService,
              private route: ActivatedRoute,
              private router: Router,
              private toastrService: ToastrService,
              private modalService: NgbModal,
              private translate: TranslateService) {

    this.translate.setDefaultLang('pt-BR');
    this.user = JSON.parse(localStorage.getItem('currentUser'));

  }

  ngOnInit() {

    this.route.params.subscribe(
      params => {
        this.customerSearch = '';
        this.status = params['status'] || '';
        this.loadProposals();
      }
    );

  }


  loadProposals() {

    this.proposals = [];

    const query = {};

    if (this.customerSearch != null && this.customerSearch.length > 3) {
      query['customer_name'] = this.customerSearch;
    }

    this.translate.get('common.loading-data').subscribe((message: string) => {

      this.toastrService.clear();

      this.toastrService.info('', message, {
        timeOut: null,
        tapToDismiss: false,
        disableTimeOut: true,
        closeButton: false,
        positionClass: 'toast-center-center'
      });

      this.apiService.list(query, 0, 100).subscribe((data: any) => {
        this.toastrService.clear();
        this.proposals = data.objects;
      }, (error) => {

        this.toastrService.clear();

        this.toastrService.error('', error, {
          timeOut: 5000,
          closeButton: true,
          positionClass: 'toast-center-center'
        });

      });

    });


  }

  editProposal(proposal: Proposal) {
    this.router.navigate([`/wizard/proposals/edit/${proposal.status}`, proposal.id]);
  }

  ngOnDestroy(): void {
    this.proposals = [];
  }

}
