import { ProposalService } from 'src/app/services/proposal.service';
import { Component, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import Proposal from 'src/app/models/Proposal';
import { WizardEditBaseComponent } from '../wizard-edit-base/wizard-edit-base.component';
import FlushType from 'src/app/models/FlushType';
import Partner from 'src/app/models/Partner';
import Worktype from 'src/app/models/Worktype';
import MeasurementType from 'src/app/models/MeasurementType';
import { MeasurementTypeService } from 'src/app/services/measurement-type.service';
import { FlushTypeService } from 'src/app/services/flush-type.service';
import { PartnerService } from 'src/app/services/partner.service';
import { analyzeFileForInjectables } from '@angular/compiler';
import Inspection from 'src/app/models/Inspection';
import { WorktypeService } from 'src/app/services/worktype.service';

@Component({
  selector: 'app-wizard-initial-inspection',
  templateUrl: './wizard-initial-inspection.component.html',
  styleUrls: ['./wizard-initial-inspection.component.css']
})


export class WizardInitialInspectionComponent extends WizardEditBaseComponent {

  buildingType: any;

  @Input() inspection: Inspection = null;

  flushTypes: FlushType[] = [];
  partners: { partner: Partner, checked: false }[] = [];
  worktypes: Worktype[] = [];

  constructor(ps: ProposalService,
    route: ActivatedRoute,
    router: Router,
    fb: FormBuilder,
    toastrService: ToastrService,
    private measurementTypeService: MeasurementTypeService,
    private flushTypeService: FlushTypeService,
    private partnerService: PartnerService,
    private worktypeService: WorktypeService,
    translate: TranslateService) {
    super(ps, route, router, fb, toastrService, translate);

  }

  measurementTypes: { measurementType: MeasurementType, checked: false }[] = [];
  files: any;

  ngOnInit() {
    super.ngOnInit();

    if (this.inspection == null) {
      this.inspection = new Inspection();
    }

  }

  afterLoadProposal() {

     if (this.inspection.id) {
      this.loadInspection();
    } else {
       this.loadFlushTypes();
       this.loadPartners();
       this.loadWorktypes();
    }

  }

  createForm() {

    if (this.inspection == null) {
      this.inspection = new Inspection();
    }

    this.angForm = this.fb.group({
      measurements: ['', ],
      date: ['', Validators.required],
      workType: ['', Validators.required],
      flushType: ['', Validators.required],
      coldWaterMeters: ['', Validators.required],
      hotWaterMeters: ['', Validators.required],
      gasMeters: ['', Validators.required],
      allUnits: ['', Validators.required],
      allUnitsComments: ['', ],
      indications: ['', ],
      comments: ['', ]

    });

  }

  loadInspection() {

    this.unsubscribe$.sink = this.apiService.get(this.inspection.id).subscribe((data: any) => {

      this.inspection = data;
      this.inspection.proposalId = this.inspection.id;

      this.loadFlushTypes();
      this.loadPartners();
      this.loadWorktypes();

    });

  }

  loadFlushTypes() {

    this.unsubscribe$.sink = this.flushTypeService.list({}, 0, 9999).subscribe((data: any) => {

      this.flushTypes = data.objects;

      if (this.inspection.flushType !== null) {

        const candidates = this.flushTypes.filter((ft: FlushType) => ft.id === this.item.flushType.id);

        if (candidates.length > 0) {
          this.inspection.flushType = candidates[0];
        }

      }

    });

  }

  loadPartners() {

    this.unsubscribe$.sink = this.partnerService.list({}, 0, 99999).subscribe((data: any) => {

      this.partners = data.objects.map((p: Partner) => {
        const newPartner = { partner: p, checked: false };
        newPartner.checked = this.inspection.indications.filter((pt: Partner) => pt.id === p.id).length > 0;
        return newPartner;
      });

    });

  }


  loadWorktypes() {

    this.unsubscribe$.sink = this.worktypeService.list({}, 0, 9999).subscribe((data: any) => {

      this.worktypes = data.objects;

      if (this.inspection.workType !== null) {

        const candidates = this.worktypes.filter((wt: Worktype) => wt.id === this.inspection.workType.id);

        if (candidates.length > 0) {
          this.inspection.workType = candidates[0];
        }

      }

    });

  }


  changedPartner(event, partner: Partner) {

    const checked = event.target.checked;

    if (checked) {
      this.inspection.indications.push(partner);
    } else {
      this.inspection.indications = this.inspection.indications.filter((p: Partner) => p.id !== partner.id);
    }

  }

  trackPartnersItemBy(index: number, item: { partner: Partner, checked: false }) {
    return item.partner.id;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.partners = [];
    this.worktypes = [];
    this.flushTypes = [];

  }

  formCheckInline(){
    if (this.inspection.workType !== null) {

      const candidates = this.worktypes.filter((wt: Worktype) => wt.id === this.inspection.workType.id);

      if (candidates.length > 0) {
        this.inspection.workType = candidates[0];
      }

    }
  }

  setItemAttributes() {
  }

}
