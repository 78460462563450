import { Injectable } from '@angular/core';
import { TastypieService } from '../shared/services/Tastypie.service';
import Worktype from '../models/Worktype';

@Injectable({
  providedIn: 'root'
})

export class WorktypeService extends TastypieService<Worktype> {

  setUri() {
    this.uri += '/v1/worktype';
  }

  list(queryParams = {}, offset: number = 0, limit: number = 10) {
    return super.list(queryParams, offset, limit, Worktype);
  }

}
