import { ProposalService } from 'src/app/services/proposal.service';
import { Component, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import Proposal from 'src/app/models/Proposal';
import { WizardEditBaseComponent } from '../wizard-edit-base/wizard-edit-base.component';

@Component({
  selector: 'app-wizard-request',
  templateUrl: './wizard-request.component.html',
  styleUrls: ['./wizard-request.component.css']
})

export class WizardRequestComponent extends WizardEditBaseComponent {

  constructor(ps: ProposalService,
    route: ActivatedRoute,
    router: Router,
    fb: FormBuilder,
    toastrService: ToastrService,
    translate: TranslateService) {
      super(ps, route, router, fb, toastrService, translate);

  }

  ngOnInit() {
    super.ngOnInit();
  }

  createForm() {

    if (this.item == null) {
      this.item = new Proposal();
    }

    this.angForm = this.fb.group({
      customerName: ['', Validators.required],
      responsibleName: ['', Validators.required],
      responsibleEmail: ['', ],
      responsiblePhone: ['', Validators.required],
      comments: ['',]
    });

  }

  setItemAttributes() {
  }

  copyPhoneToClipBoard() {

    const phone = this.item.responsiblePhone;

    const x = document.createElement('TEXTAREA') as HTMLTextAreaElement;
    x.value = phone;
    document.body.appendChild(x);
    x.select();
    document.execCommand('copy');
    document.body.removeChild(x);
  }

}
