export default class Model {

  id: number;
  editable = true;
  deletable = false;
  deleted?: boolean;
  resourceUri: string;

  constructor(obj?: any) {
    Object.assign(this, obj);
  }

  hasActions?(): boolean {
    return this.editable || this.deletable;
  }

}
