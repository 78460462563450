import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpLoaderFactory } from 'src/app/app.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { Routes, RouterModule } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';
import { TimeagoIntl, TimeagoModule, TimeagoFormatter, TimeagoCustomFormatter } from 'ngx-timeago';
import { NgSelectModule } from '@ng-select/ng-select';
import { TruncateModule } from 'ng2-truncate';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxMaskModule } from 'ngx-mask';
import { MeasurementTypeResolve } from 'src/app/resolvers/measurement-type.resolve';
import { CustomerTypeResolve } from 'src/app/resolvers/customer-type.resolve';
import { NgxImageCompressService } from 'ngx-image-compress';
import { AuthGuard } from 'src/app/shared/guards/auth.guard';
import { ProposalListComponent } from './proposal-list/proposal-list.component';
import { WizardEditBaseComponent } from './wizard-edit-base/wizard-edit-base.component';
import { WizardRequestComponent } from './wizard-request/wizard-request.component';
import { WizardWrapperComponent } from './wizard-wrapper/wizard-wrapper.component';
import { WizardEmptyComponent } from './wizard-empty/wizard-empty.component';
import { WizardQualificationComponent } from './wizard-qualification/wizard-qualification.component';
import { WizardEstimateComponent } from './wizard-estimate/wizard-estimate.component';
import { WizardInitialInspectionComponent } from './wizard-initial-inspection/wizard-initial-inspection.component';
import { from } from 'rxjs';
import { WizardFinalInspectionComponent } from './wizard-final-inspection/wizard-final-inspection.component';
import { WizardAnalysisComponent } from './wizard-analysis/wizard-analysis.component';

export class MyIntl extends TimeagoIntl {}

const routes: Routes = [
  {
    path: 'proposals', component: ProposalListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'proposals/:status', component: ProposalListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'proposals/add', component: WizardRequestComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'proposals/edit/request/:id', component: WizardRequestComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'proposals/edit/qualification/:id', component: WizardQualificationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'proposals/edit/estimate/:id', component: WizardEstimateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'proposals/edit/initial_inspection/:id', component: WizardInitialInspectionComponent,
    canActivate: [AuthGuard]
  },

  {
    path: 'proposals/edit/request/:id', component: WizardEmptyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'proposals/edit/analysis/:id', component: WizardAnalysisComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'proposals/edit/final_inspection/:id', component: WizardFinalInspectionComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'proposals/edit/review/:id', component: WizardEmptyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'proposals/edit/contract/:id', component: WizardEmptyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'proposals/edit/signed/:id', component: WizardEmptyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'proposals/edit/refused/:id', component: WizardEmptyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'proposals/edit/refused/:id', component: WizardEmptyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'proposals/edit/pre-proposal/:id', component: WizardEmptyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'proposals/edit/trash/:id', component: WizardEmptyComponent,
    canActivate: [AuthGuard]
  },

];

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    SharedModule,
    NgSelectModule,
    TruncateModule,
    NgxFileDropModule,
    NgxMaskModule.forChild(),
    RouterModule.forChild(routes),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    TimeagoModule.forRoot({
      intl: { provide: TimeagoIntl, useClass: MyIntl },
      formatter: { provide: TimeagoFormatter, useClass: TimeagoCustomFormatter },
    })
  ],
  entryComponents: [

  ],
  declarations: [
    ProposalListComponent,
    WizardEditBaseComponent,
    WizardRequestComponent,
    WizardWrapperComponent,
    WizardEmptyComponent,
    WizardQualificationComponent,
    WizardInitialInspectionComponent,
    WizardFinalInspectionComponent,
    WizardEstimateComponent,
    WizardAnalysisComponent


  ],
  exports: [
  ],
  providers: [
    AuthGuard,
    NgbActiveModal,
    CustomerTypeResolve,
    MeasurementTypeResolve,
    NgxImageCompressService,
    ConfigService,
  ]
})

export class WizardModule { }
