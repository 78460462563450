import { Component, OnInit, OnDestroy } from '@angular/core';
import { timer } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-performance-info',
  templateUrl: './performance-info.component.html',
  styleUrls: ['./performance-info.component.css']
})

export class PerformanceInfoComponent implements OnInit, OnDestroy {

  INTERVAL = 10000;

  private scheduler;

  currentTotalBytes = 0;
  previousTotalBytes = 0;
  totalBytesDelta = 0;
  totalBytesDeltaPct = 0;

  constructor() {

  }

  ngOnInit() {

    this.scheduler = timer(0, this.INTERVAL);
    this.scheduler.subscribe((t) => this.checkMemory());

  }

  checkMemory() {

    const memoryInfo: any = window.performance;

    if (memoryInfo.measureMemory) {

      const promise = memoryInfo.measureMemory();

      promise.then(result => {

        this.previousTotalBytes = this.currentTotalBytes;
        this.currentTotalBytes = result.bytes;
        this.totalBytesDelta = this.currentTotalBytes - this.previousTotalBytes;

        if (this.previousTotalBytes > 0) {
          this.totalBytesDeltaPct = this.totalBytesDelta / this.previousTotalBytes;
        } else {
          this.totalBytesDeltaPct = 0;
        }

      }, err => {
        console.error(err);
      });

    }

  }

  ngOnDestroy() {
  }

}
