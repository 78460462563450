import { Component, OnInit, OnDestroy } from '@angular/core';
import { fromEvent, Subscription, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-browser-event',
  templateUrl: './browser-event.component.html',
  styleUrls: ['./browser-event.component.css']
})

export class BrowserEventComponent implements OnInit, OnDestroy {

  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;

  subscriptions: Subscription[] = [];

  alertTimeout = 3000;

  constructor(private toastrService: ToastrService,
              private translate: TranslateService) { }

  ngOnInit() {

    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(this.onlineEvent.subscribe(e => {

      this.translate.get('browser-alert.online').subscribe((message: string) => {

        this.toastrService.success(message, '', {
          timeOut: this.alertTimeout,
          closeButton: true,
          positionClass: 'toast-bottom-right'
        });

      });

    }));

    this.subscriptions.push(this.offlineEvent.subscribe(e => {

      this.translate.get('browser-alert.offline').subscribe((message: string) => {

        this.toastrService.error(message, '', {
          timeOut: this.alertTimeout,
          closeButton: true,
          positionClass: 'toast-bottom-right'
        });

      });

    }));

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
