import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import User from '../../models/User';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @Input() user: User;
  @Input() debug = true;

  @Output() debugOutput = new EventEmitter<boolean>();

  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit() {
  }

  logout() {
    this.authenticationService.logout();
  }

  emitDebug() {
    this.debug = !this.debug;
    this.debugOutput.emit(this.debug);
  }

}
