import { NgModule, LOCALE_ID } from '@angular/core';
import { FooterComponent } from './components/footer/footer.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModalComponent } from './components/modal/modal.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { TableComponent } from './components/table/table.component';
import { CommonModule } from '@angular/common';
import { ListComponent } from './components/list/list.component';
import { TitleComponent } from './components/title/title.component';
import { AlertComponent } from './components/alert/alert.component';
import { BrowserEventComponent } from './components/browser-event/browser-event.component';
import { HttpLoaderFactory } from '../app.module';
import { FormGroupComponent } from './components/form-group/form-group.component';
import { FormsModule } from '@angular/forms';
import { PerformanceInfoComponent } from './components/performance-info/performance-info.component';
import { NgBytesPipeModule } from 'angular-pipes';
import { NgbActiveModal, NgbTimeAdapter, NgbDateParserFormatter, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from './services/authentication.service';
import { ConfigService } from '../services/config.service';
import { AlertService } from './services/alert.service';
import { UserResolve } from '../resolvers/user.resolve';
import { ToastrService } from 'ngx-toastr';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { Router } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { MyDateAdapter, MyDateParserFormatter } from './adapters/MyDateAdapter';
import { MyTimeAdapter } from './adapters/MyTimeAdapter';
import { HighchartsChartModule } from 'highcharts-angular';
import { LineChartComponent } from './components/charts/line-chart/line-chart.component';
import { PieChartComponent } from './components/charts/pie-chart/pie-chart.component';
import { BarChartComponent } from './components/charts/bar-chart/bar-chart.component';
import { ColumnChartComponent } from './components/charts/column-chart/column-chart.component';

@NgModule({
  declarations: [
    FooterComponent,
    ModalComponent,
    PaginationComponent,
    TableComponent,
    ListComponent,
    TitleComponent,
    AlertComponent,
    FormGroupComponent,
    BrowserEventComponent,
    PerformanceInfoComponent,
    LineChartComponent,
    PieChartComponent,
    BarChartComponent,
    ColumnChartComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgBytesPipeModule,
    HighchartsChartModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    FooterComponent,
    ModalComponent,
    PaginationComponent,
    TableComponent,
    ListComponent,
    TitleComponent,
    AlertComponent,
    FormGroupComponent,
    BrowserEventComponent,
    PerformanceInfoComponent,
    AlertComponent,
    LineChartComponent,
    PieChartComponent,
    BarChartComponent,
    ColumnChartComponent
  ],
  providers: [
    AuthGuard,
    NgbActiveModal,
    AuthenticationService,
    ConfigService,
    AlertService,
    UserResolve,
    ToastrService,
    { provide: NgbDateAdapter, useClass: MyDateAdapter },
    { provide: NgbDateParserFormatter, useClass: MyDateParserFormatter },
    { provide: NgbTimeAdapter, useClass: MyTimeAdapter },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true,
      deps: [AlertService, ToastrService, AuthenticationService, Router] },
    { provide: LOCALE_ID, useValue: 'pt-PT' },
  ],
})

export class SharedModule { }
