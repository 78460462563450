import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import User from '../../models/User';
import { Router } from '@angular/router';
import { ConfigService } from '../../services/config.service';

@Injectable()
export class AuthenticationService {

  @Output() loggedIn = new EventEmitter<User>();
  @Output() refreshLogin = new EventEmitter<User>();
  @Output() loggedOut = new EventEmitter<any>();

  loginUri = '/login/';
  loginRefreshUri = '/login/refresh/';
  loginResetPasswordRequestUri = '/login/reset-password-request/';
  loginSetPasswordUri = '/login/set-password/';

  constructor(public router: Router, private http: HttpClient, private config: ConfigService) {
    this.loginUri = config.baseUrl + '/login/';
    this.loginRefreshUri = config.baseUrl + '/login/refresh/';
    this.loginResetPasswordRequestUri = config.baseUrl + '/login/reset-password-request/';
    this.loginSetPasswordUri = config.baseUrl + '/login/set-password/';
  }

  login(username: string, password: string) {

    const obj = { username, password };

    return this.http.post<any>(`${this.loginUri}`, obj)
      .pipe(map((data: any) => {

        let user: User = null;

        if (data != null && data.status) {

          user = data;

          if (user && user.apiKey) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.loggedIn.emit(user);
          }

        } else {

          if (!data.status) {
            throw(data.message);
          } else {
            const message = '?';
            throw(message);
          }

        }

        return user;

      }));
  }

  updateLogin() {

    const obj = {};

    return this.http.post<any>(`${this.loginRefreshUri}`, obj)
    .pipe(map((data: any) => {

      let user: User = null;

      if (data != null && data.status) {

        user = data;

        if (user && user.apiKey) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.refreshLogin.emit(user);
        }

      } else {

        if (!data.status) {
          throw(data.message);
        } else {
          const message = '?';
          throw(message);
        }

      }

      return user;

    }));

  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.loggedOut.emit();
    this.router.navigate([{outlets: {primary: '/login/' , login: 'login'}}]);
  }

  resetPasswordRequest(username: string) {

    const obj = { username };

    return this.http.post<any>(`${this.loginResetPasswordRequestUri}`, obj)
    .pipe(map((data: any) => {

      if (data != null && data.status) {

        return data;

      } else {

        if (!data.status) {
          throw(data.message);
        } else {
          const message = '?';
          throw(message);
        }

      }

    }));

  }

  setPassword(key: string, newPassword: string, newPasswordConfirmation: string) {

    const obj = {key, newPassword, newPasswordConfirmation };

    return this.http.post<any>(`${this.loginSetPasswordUri}`, obj)
    .pipe(map((data: any) => {

      if (data != null && data.status) {

        return data;

      } else {

        if (!data.status) {
          throw(data.message);
        } else {
          const message = '?';
          throw(message);
        }

      }

    }));

  }

}
