import { Injectable } from '@angular/core';
import { TastypieService } from '../shared/services/Tastypie.service';
import Block from '../models/Block';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class BlockService extends TastypieService<Block> {

  setUri() {
    this.uri += '/v1/block';
  }

  list(queryParams = {}, offset: number = 0, limit: number = 10) {
    return super.list(queryParams, offset, limit, Block);
  }

  generateUnits(numberOfUnits: number, firstUnit: string, lastUnit: string, step: string, unitsPerStage: string, stages: string) {

    var params = new HttpParams();

    params = params.set('number_of_units', `${numberOfUnits}`);
    params = params.set('first_unit', firstUnit);
    params = params.set('last_unit', lastUnit);
    params = params.set('step', `${step}`);
    params = params.set('units_per_stage', `${unitsPerStage}`);
    params = params.set('stages', `${stages}`);

    return this.http.get(`${this.uri}/units-generator/`, { params });
  }

}
